import React, { useRef } from 'react';
import { graphql, Link } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import LazyLoad from 'react-lazyload';

import PublicationImage from '../components/images/publicationImage';

const PublicationContainer = styled.div`
  margin-bottom: 100px;
  text-align: center;

  & > a {
    text-decoration: none;
    &:hover {
      color: red;
    }
  }
`;

const Stockist = styled.div`
  margin-bottom: 40px;
  display: flex;
`;

const PublicationAnchor = styled.div`
display: block;
margin-top:-112px !important;
position:absolute;
top:0:
left:0;`;

const PublicationIntro = styled.div`
  margin-bottom: 100px;

  > div {
    max-width: 655px;
    margin: 0 auto;
  }
  & > a {
    text-decoration: none;
    &:hover {
      color: red;
    }
  }
`;

const StockistButton = styled.button`
  margin: 60px 0 0 0;
  font-size: 28px;

  @media (max-width: 767px) {
    margin: 30px 0 0 0;
    font-size: 27px;
  }

  @media (max-width: 420px) {
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }

  background: transparent;
  padding: 0;
  border: 0;
  color: black;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

const StockistLink = styled.a`
  text-decoration: none;
  &:hover {
    color: red;
  }
`;

const PublicationTitle = styled.h2`
  margin: 15px 0 0 0;
  font-size: 28px;

  @media (max-width: 767px) {
    margin: 30px 0 0 0;
    font-size: 27px;
  }

  @media (max-width: 420px) {
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const StockistTitle = styled.h2`
  font-size: 28px;
  text-align: center;
  margin-top: 75px;
  margin-bottom: 100px;
`;

const Title = styled.h2`
  font-size: 28px;
  text-align: center;
  margin-top: 144px;
  margin-bottom: 100px;
`;

const TextContainer = styled.div`
  margin: ${({ mobileMargin }) => mobileMargin || '0'};
`;

const StockistContainer = styled.div`
  > p {
    &:first-child {
      margin-top: 0;
    }
  }
`;

const Publications = ({
  data: {
    allPrismicPublication,
    allPrismicStockist,
    allPrismicBookFair,
    prismicSettings,
    prismicCollections,
  },
}) => {
  const stockistsRef = useRef(null);

  const scrollToStockists = () => {
    if (stockistsRef.current) {
      window.scrollTo({
        top: stockistsRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const sortedStockists = allPrismicStockist.edges.sort((a, b) => {
    const nameA = a.node.data.title.text.toUpperCase();
    const nameB = b.node.data.title.text.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const sortedBookFairs = allPrismicBookFair.edges.sort((a, b) => {
    const nameA = a.node.data.title.text.toUpperCase();
    const nameB = b.node.data.title.text.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const stockistsList = sortedStockists.map((stockist, index) => (
    <Stockist key={index}>
      {stockist.node.data.link ? (
        <StockistLink target="_blank" href={stockist.node.data.link}>
          {stockist.node.data.title.text}
          {stockist.node.data.address && (
            <StockistContainer
              dangerouslySetInnerHTML={{
                __html: stockist.node.data.address.html,
              }}
            />
          )}
        </StockistLink>
      ) : (
        <>
          {stockist.node.data.title.text}
          {stockist.node.data.address && (
            <StockistContainer
              dangerouslySetInnerHTML={{
                __html: stockist.node.data.address.html,
              }}
            />
          )}
        </>
      )}
    </Stockist>
  ));

  const bookFairsList = sortedBookFairs.map((bookFair, index) => (
    <Stockist key={index}>
      {bookFair.node.data.link ? (
        <StockistLink target="_blank" href={bookFair.node.data.link}>
          {bookFair.node.data.title.text}
          {bookFair.node.data.address && (
            <StockistContainer
              dangerouslySetInnerHTML={{
                __html: bookFair.node.data.address.html,
              }}
            />
          )}
        </StockistLink>
      ) : (
        <>
          {bookFair.node.data.title.text}
          {bookFair.node.data.address && (
            <StockistContainer
              dangerouslySetInnerHTML={{
                __html: bookFair.node.data.address.html,
              }}
            />
          )}
        </>
      )}
    </Stockist>
  ));

  const allPublications = allPrismicPublication.edges.map(
    (publication, index) => {
      return (
        <Col col={12} sm={6} key={index}>
          <LazyLoad height={400}>
            <PublicationContainer>
              <Link to={'/publications/' + publication.node.uid}>
                <PublicationImage image={publication.node.data.gallery} />
                <PublicationTitle>
                  {publication.node.data.author}
                  <br />
                  {publication.node.data.title.text}
                </PublicationTitle>
              </Link>
            </PublicationContainer>
          </LazyLoad>
        </Col>
      );
    }
  );

  return (
    <>
      <Helmet title={`Publications - Askeaton Contemporary Arts`} />
      <Col col={12} md={10}>
        <PublicationIntro>
          <Col col={12} lg={8}>
            <StockistButton onClick={scrollToStockists}>
              Go to stockists, book fairs and collections
            </StockistButton>
          </Col>
        </PublicationIntro>
        <Row>{allPublications}</Row>
        <PublicationIntro>
          <PublicationAnchor ref={stockistsRef}></PublicationAnchor>
          <Col col={12} lg={8}>
            {prismicSettings && prismicSettings.data && (
              <TextContainer
                dangerouslySetInnerHTML={{
                  __html: prismicSettings.data.publications_introduction.html,
                }}
              />
            )}
          </Col>
        </PublicationIntro>

        <PublicationIntro>
          <Col col={12} lg={8}>
            {stockistsList.length > 0 && (
              <div>
                <StockistTitle>{'Stockists'}</StockistTitle>
                {stockistsList}
              </div>
            )}

            {bookFairsList.length > 0 && (
              <div>
                <Title>{'Book Fairs'}</Title>
                {bookFairsList}
              </div>
            )}
            {prismicCollections.data.collections.html && (
              <div>
                <Title>{'Collections'}</Title>
                <TextContainer
                  dangerouslySetInnerHTML={{
                    __html: prismicCollections.data.collections.html,
                  }}
                />
              </div>
            )}
          </Col>
        </PublicationIntro>
      </Col>
    </>
  );
};

export default Publications;

export const pageQuery = graphql`
  query MyQuery {
    allPrismicPublication(sort: { order: DESC, fields: data___year }) {
      edges {
        node {
          uid
          data {
            year
            title {
              text
            }
            author
            gallery {
              gallery_image {
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
    allPrismicStockist {
      edges {
        node {
          data {
            title {
              text
            }
            link
            address {
              html
            }
          }
        }
      }
    }
    allPrismicBookFair {
      edges {
        node {
          data {
            title {
              text
            }
            link
            address {
              html
            }
          }
        }
      }
    }
    prismicCollections {
      data {
        collections {
          html
        }
      }
    }
    prismicSettings {
      data {
        publications_introduction {
          html
        }
      }
    }
  }
`;
