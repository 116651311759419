import React from 'react';
import styled from 'styled-components';
import Decimal from 'decimal.js-light';

const CarouselContainer = styled.div`
  margin: ${props => props.margin || '0 0 0 0'};
  width: 100%;
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 66.666%;
  }
`;

const LandscapeImage = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
  position: absolute !important;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  &:first-of-type {
    z-index: 1;
  }
`;

function closestValue(array, value) {
  var result, lastDelta;

  array.some(function(item) {
    var delta = Math.abs(value - item);
    if (delta >= lastDelta) {
      return true;
    }
    result = item;
    lastDelta = delta;
  });
  return result;
}

let previousBreakpoint = 0;

class PublicationGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageCount: 0,
      imageWidth: 0,
      transitionBreakpoints: [],
      x: 0,
    };

    this.onMouseMove = this.onMouseMove.bind(this);
    this.resetCarousel = this.resetCarousel.bind(this);
    this.calculateTransitionBreakpoints = this.calculateTransitionBreakpoints.bind(
      this
    );
    this.updateTransitionBreakpoints = this.updateTransitionBreakpoints.bind(
      this
    );
    this.carouselContainer = React.createRef();
  }

  componentDidMount(props) {
    let imageWidth = new Decimal(
      this.carouselContainer.current.getBoundingClientRect().width
    );

    this.setState(
      {
        imageCount: this.props.images.length,
        imageWidth: imageWidth,
      },
      () => {
        this.calculateTransitionBreakpoints(this.carouselContainer);
      }
    );
  }

  updateTransitionBreakpoints(props) {
    let imageWidth = new Decimal(props.current.getBoundingClientRect().width);

    this.setState(
      {
        imageWidth: imageWidth,
      },
      () => {
        this.calculateTransitionBreakpoints(this.carouselContainer);
      }
    );
  }

  calculateTransitionBreakpoints() {
    let transitionBreakpoints = [];

    for (let i = 0; i < this.state.imageCount; i++) {
      if (i === 0) {
        transitionBreakpoints.push(0);
      } else {
        transitionBreakpoints.push(
          this.state.imageWidth
            .dividedBy(this.state.imageCount)
            .times(i)
            .toDecimalPlaces(2)
            .toNumber()
        );
      }
    }

    //https://alligator.io/js/array-sort-numbers/
    this.setState({
      transitionBreakpoints: transitionBreakpoints.sort((a, b) => a - b),
    });
  }

  onMouseMove(e, container) {
    let target = container.current;
    let targetChildNodes = Array.from(target.childNodes);
    this.setState({ x: e.nativeEvent.offsetX }, () => {
      //https://stackoverflow.com/a/35000557/3552281

      let activeBreakpoint = closestValue(
        this.state.transitionBreakpoints,
        this.state.x
      );

      if (activeBreakpoint !== previousBreakpoint) {
        let imageArrayIndex = this.state.transitionBreakpoints.indexOf(
          activeBreakpoint
        );

        targetChildNodes.forEach((element, index) => {
          if (index === imageArrayIndex) {
            element.style.zIndex = 1;
          } else {
            element.style.zIndex = 0;
          }
        });
      }

      previousBreakpoint = activeBreakpoint;
    });
  }

  resetCarousel(e, container) {
    let target = container.current;
    let targetChildNodes = Array.from(target.childNodes);
    targetChildNodes.forEach((element, index) => {
      element.style.zIndex = 0;
    });
    targetChildNodes[0].style.zIndex = 1;
  }

  render(props) {
    const galleryImages = this.props.images
      .filter(image => image.gallery_image.url !== null)
      .map((imageData, index) => (
        <LandscapeImage
          key={index}
          src={imageData.gallery_image.url}
          // loading="lazy"
          // srcSet={`${imageData.gallery_image.url}&h=800&crop=entropy&q=80 1x,
          //         ${imageData.gallery_image.url}&h=800&crop=entropy&dpr=2&q=80 2x,
          //         ${imageData.gallery_image.url}&h=800&crop=entropy&dpr=3&q=80 3x`}
          // src={`${imageData.gallery_image.url}&h=800&crop=entropy&q=80`}
        />
      ));

    return (
      <CarouselContainer
        margin={this.props.margin}
        onMouseMove={e => this.onMouseMove(e, this.carouselContainer)}
        onMouseLeave={e => this.resetCarousel(e, this.carouselContainer)}
        ref={this.carouselContainer}
      >
        {galleryImages}
      </CarouselContainer>
    );
  }
}

export default PublicationGallery;
